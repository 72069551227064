
#intranet-news {
    position: relative;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, .5) url(../images/icons/loading.gif) 50% center no-repeat;
        z-index: 1000;
    }

}