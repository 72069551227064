.picklist {
    .dirty {
       color: red;
       font-weight: bold;
       display: inline-block;
       padding: 0 emCalc(2.5px);
    }
    .active {
    }
    
    li {
       overflow: hidden;
       white-space: nowrap;
    }
    table {
        width: 100%;
    }
    
    .busy {
       table {
           opacity: .5;
       }
    }
    
    &.collapsed {
    
       position: relative;
    
       li {
           padding-bottom: 0;
       }
    }
    
    &.panel {
        max-height: emCalc(300px);
        overflow: auto;
    }
}