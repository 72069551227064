.k-tabstrip-items {
    & > li {
        background: $grey;
    }
}

.k-tabstrip:focus {
    box-shadow: none;
}

.k-tabstrip-items .k-state-default .k-link,
.k-panelbar > li.k-state-default > .k-link {
    background: $grey;
}
.k-tabstrip-items .k-state-active a,
.k-panelbar .k-tabstrip-items .k-state-active a,
.k-ie7 .k-tabstrip-items .k-state-active .k-loading a{
    background: #fff none !important;
    border: none;
}
.k-state-active > a {
    color: #fff;
}

.row .row {
    margin: 0;
}

.k-tabstrip>.k-content,
.k-panelbar .k-tabstrip>.k-content {
    border: 1px solid $grey;
}

