.icon-list {
    
    margin-bottom: emCalc(-10px);
    
    li {
    
        a {
           transition: background-color 0.2s ease-in, color 0.2s ease-in;
           background-color: #94a623;
           background-repeat: no-repeat;
           display: block;
           line-height: 32px;
           height: 160px;
           font-weight: bold;
           
           &:active, &:hover {
               background-color: #94a623;
               color: #183A5F;
               text-decoration: none;
           }
        }
    }
}