
todo {
	background: #FFFF6C;
	color: red;
	font-size: 20px;
	display: block;
	margin: 2em;
	padding: .5em;
}

#process-configurator {
    button.disabled.success,
    button[disabled].success,
    .button.disabled.success,
    .button[disabled].success {
        background: #999500 !important;
        border: darken(#999500, 10%) !important;
        cursor: pointer;
    }
    position: relative;
    fieldset > .row-border {
        border-top: 1px solid #ccc;
        padding-top: 1em;
    }
    fieldset,
    .tab-strip {
        border: none;
    }

    .link.bordered {
        border-bottom: 1px solid #ccc;
        margin: 1.125rem 0;
        padding: 1.25rem;
    }
    .blending-image {
        //border: 1px solid #c6c6c6;
        width: 100%;
        margin-bottom: 4px;
        img {
            border: 4px solid #fff;
            width: 100px;
            &.checked {
                border: 4px solid #122454;
            }
        }
    }
    .cb {
        position: relative;
        width: 20px;
        float: left;
    }
    .white-bg {
        background: #fff !important;
    }
    table {
        tbody.selected * {
            background: rgba(#bebb36, 0.25);
        }
        td.selected {
            background: rgba(#bebb36, 0.5);
        }
        tfoot {
	        font-size: 110%;
	    }
        tr th, tr td {
	        font-size: 0.95em;
            text-transform: none;
	    }
	    td > .row {
	       margin: 0;
	    }
        &.white {
            tr th, tr td {
                background-color: #fff !important;
            }
            td{
                padding: 0 5px;
            }
        }
        &.result {
            //background: #fff;
            th {
                background: #fff;
                text-align: left;
                vertical-align: top;
            }
            tr.button-row {
                background: #fff;
                padding: 0;
                margin: 0;
            }
        }
        button.secondary {
            background-color: #999500;
            color: #fff;
        }
    }
    .toeren {
        div:last-child {
            //border-left: 1px solid #c6c6c6;
        }
    }

    .optional {
        position: relative;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, .8);
        z-index: 1000;
    }

    .tab-strip {

        @include clearfix();

	    & > .tab-content {
	        padding-top: emCalc(20px);
	    }

	    & > button {
	       margin: 0 .29em .3em;

	       &.prev {
	           float: left;
	       }
	       &.next {
	           float: right;
	       }
	    }
    }
    .alert-box,
    .alert-box-normal {
        margin-bottom: 20px;
        margin-top: 20px;
        border: none;
        background: rgba(153, 149, 0, 0.5);
        color: $green;
        width: 100%;
        border-radius: 0 !important;
        position: relative;
        padding: 1rem 1rem 1rem 60px;
        font-size: 15px;
        &:before {
            content: url('/images/alert-img.png');
            position: absolute;
            justify-content: center;
            align-items: center;
            display: flex;
            left:0;
            top: 0;
            bottom: 0;
            background: $green;
            width: 50px;
            height: 100%;
            color: lighten($green, 10%);
        }
    }
	.alert-box.turbulent {
		background: $green !important;
	}
	.alert-box.laminair {
		background: $red !important;
	}
	.alert-box.overgangsgebied {
		background: $orange !important;
	}
    .bordered {
        thead th,
        thead td {
            border: none;
        }
    }
    fieldset button.close {
        position: absolute;
        top: -27px;
        right: 0;
    }
    .k-block > .k-header, .k-window-titlebar {
        display: block;
        min-height: 36px;
    }

    .process-sheet {
        &__id {
            font-weight: bold;
            font-size: 1.3em;
        }
    }
}


//SIDE MENU
.side-menu {
    padding-right: 1em;
    padding-top: 1.5em;
    height: 100%;
    line-height: 2em;
    &__name {
        color: $green;
        font-size: 1.1em;

    }
	&__title {
        font-weight: bold;
        color: #122454;
        font-size: 1.1em;
	}
	&__menu {
		line-height: 2em;
        padding-bottom: 1.5em;
	}
	&__list {
		list-style: none;
        color: #686868;
        margin: 0;
	}
	&__sublist {
        .side-menu__item {
        }
	}
	&__item {
        color: $green;
        a {
            display: block;
            padding-left: 6px;
            &:hover{
                text-decoration: none;
                background: #f2f2f2;
            }
        }
        .active {
          background: #f2f2f2;
          color: $black;
            a {
                background: #f2f2f2;
                color: $black;
            }
        }
	}
	&__link {
        color: #8f8f8f;
        &--green {
            color: $green;
        }
	}

    &__toplink {
        //margin-top: 10px;
        //margin-bottom: 10px;
        //border-top: 1px solid $green;
        border-bottom: 1px solid $green;
        color: $green;
        font-size: 1.1em;
    }
}


fieldset {
    &.borderd {
        border-top: 1px solid #ccc !important;
        margin-top: 0;
    }
    &.small {
        margin: 0;
        padding: 0.5em 1.25rem;
    }
}

.row.borderd {
    border-top: 1px solid #ccc !important;
    padding: 10px 0;
}


fieldset legend {
    padding: 0;
    color: $green;
    font-size: 15px;
    margin: 0 0 16px;
    border: none;
}

.prefix,
.postfix {
    border: none !important;
    display: inline-block;
    /* height: auto; */
    text-align: left;
    margin-left: 5px;
    background-color: transparent !important;
    z-index: 2;
}

.k-tabstrip > .k-content,
.k-panelbar .k-tabstrip > .k-content {
    border: none !important;
}

.fi-info.help {
    transition: color 250ms ease-out;
    font-size: 120%;
    border: 1px solid #ccc;
    background: $grey;
    padding: 1px 7px;
    &:before {
        content: "i";
        font-family: serif;
        font-size: 0.9em;
    }
}

th.line {
    border-left: 1px solid $grey !important;
    border-right: 1px solid $grey !important;
}

.target-question {
    padding: 20px 0;
}

#process-configurator .result-column {
    .alert-box {
        margin: 0;
        padding: 0;
        border: none;
        background: red;
        width: 10px;
        height: 10px;
        border-radius: 100% !important;
        position: relative;
        cursor: pointer;
        &:before {
            content: none;
            display: none;
        }
        div {
            background: red;
            position: absolute;
            top: -32px;
            padding: 5px;
            min-width: 140px;
            display: block;
            color: #fff;
            text-align: center;
            left: -20px;
            cursor: pointer;
            opacity: 0;
            transition: all 0.3s;
            &:after {
                content: '';
                position: absolute;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                bottom: -5px;
                left: 15px;
            }
        }
		div.turbulent {
			background: $green !important;
			&:after {
				border-top: 10px solid $green;
			}
		}
		div.laminair {
			background: $red !important;
			&:after {
				border-top: 10px solid $red;
			}
		}
		div.overgangsgebied {
			background: $orange !important;
			&:after {
				border-top: 10px solid $orange;
			}
		}
        &:hover {
            & > div {
                opacity: 1;
            }
        }
    }
    th {
        vertical-align: top;
        background: #fff;
    }
    table, th, tr, td {
        border: none !important;

    }
    tr {
        border-top: 1px solid #ccc !important;
    }
    thead > tr {
        border-top: none !important;
    }
}