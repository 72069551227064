@charset "UTF-8";
@import "settings";

@import "../../bower_components/foundation/scss/normalize";

@import "../../stylesheets/vendor/foundation-icons/foundation-icons.css",
"../../bower_components/foundation/scss/foundation/components/alert-boxes",
"../../bower_components/foundation/scss/foundation/components/block-grid",
"../../bower_components/foundation/scss/foundation/components/button-groups",
"../../bower_components/foundation/scss/foundation/components/buttons",
"../../bower_components/foundation/scss/foundation/components/forms",
"../../bower_components/foundation/scss/foundation/components/grid",
"../../bower_components/foundation/scss/foundation/components/labels",
"../../bower_components/foundation/scss/foundation/components/panels",
"../../bower_components/foundation/scss/foundation/components/tables",
"../../bower_components/foundation/scss/foundation/components/thumbs",
"../../bower_components/foundation/scss/foundation/components/top-bar",
"../../bower_components/foundation/scss/foundation/components/visibility";

@import "components/picklist";
@import "components/iconlist";
@import "components/processconfigurator";
@import "components/explorertool";
@import "components/news";
@import "common.scss";
@import url("../../fonts/bankgthd-webfont/stylesheet.css");
@import url("../../fonts/proxima-nova/stylesheet.css");
@import "custom-kendo";
.k-widget {

    &.large {
        display: block;
        width: 100%;
    }

    .k-content * {
        box-sizing: border-box;
    }

    .k-state-disabled {
        opacity: 1;

        .k-link {
            color: #999;

            &:hover {
                color: #999 !important;
            }
        }

        &.disabled {
            background: lightgrey;
        }
    }

    .k-state-hover {
        border: 1px solid #999 !important;
        z-index: 999;
    }
    + .error {
        margin: 1rem 0;
    }
}



.k-tabstrip-items {

    padding: .3em 0 .3em !important;

    .k-loading {
        display:none;
    }

    .k-item{
        border-bottom: 1px solid #dbdbdb;
        &:hover {

        }

        .k-link {
            color: #999;

            &:hover {
                color: #666;
            }
        }
    }

    .k-state-active{
        border-bottom: 0;
        border-color: #ccc;

        .k-link {
            color: #666;
        }

        &:hover {
            padding: 0 !important;
            border: 1px solid #999;
        }
    }

    .k-state-default.invalid .k-link {
        color: $error-color;
    }
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select,
span.postfix,
label.postfix {
    font-family: Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
    border: 1px solid darken($secondary-color, 13);
    height: 2.5rem;

    &.compact {
        margin: 0;
    }
}

input[type="file"],
input[type="checkbox"],
input[type="radio"],
select {
    &.compact {
        margin: 0;
    }
}

.k-tool.k-state-hover {
    color: #fff;
    background-color: #1984c8;
    border-color: #1a87cd;
}

.k-textbox > input,
.k-autocomplete .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input {
    height: 2.31rem;
    line-height: 1.89rem;
    box-sizing: border-box;
    text-indent: $form-spacing / 2;

    .k-editor-toolbar & {
        height: 2rem;
    }
}

.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-textbox,
.k-toolbar,
.k-split-button {
    width: 100%;
}

.k-dropdown .k-select,
.k-state-default > .k-select {
    width: 2.39rem;
    line-height: 1.89rem;

    .k-icon {
        //margin-top: .4rem;
    }

    .k-editor-toolbar & {
        width: 2rem;

        .k-icon {
            margin-left: 0;
        }
    }
}

// TODO create 1 selector?
*::-webkit-input-placeholder {
    color: $input-placeholder-font-color !important;
    font-style: italic !important;
    font-weight: normal;
}

*::-moz-placeholder {
    color: $input-placeholder-font-color !important;
    font-style: italic !important;
    font-weight: normal;
}

*:-ms-input-placeholder {
    color: $input-placeholder-font-color !important;
    font-style: italic !important;
    font-weight: normal;
}

html {
    font-size: 13px;
    overflow-y: scroll;
}

body {
    color: lighten($secondary-color2, 20);
    //    font-size: rem-calc(14);
    line-height: rem-calc(20);
    font-family: Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
    min-width: 600px;

    section {
        margin-bottom: 100px;
    }
}

label.inline {
    padding: .5em 0;
}

select {
    padding-left: .5em;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input[type="text"][disabled],
input[type="password"][disabled],
input[type="date"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="month"][disabled],
input[type="week"][disabled],
input[type="email"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="time"][disabled],
input[type="url"][disabled],
textarea[disabled],
.k-dropdown-wrap.k-state-disabled,
.k-state-disabled .k-select {
    background-color: $secondary-color;
}

button,
.button {

    box-shadow: none;
    transition: none;

    border: 1px solid $primary-color;

    padding: $button-med $button-med * 2;

    &.static {
        width: rem-calc(175);
    }

    &.tiny {
        padding: $button-tny $button-tny * 2;
        font-weight: normal;
    }

    &.small {
        padding: $button-sml $button-sml * 2;
    }

    &.large {
        padding: $button-lrg $button-lrg * 2;
    }

    &.compact {
        margin: 0;
    }

    &.disabled,
    &[disabled] {
        &,
        &.secondary {
            font-style: normal;
            cursor: not-allowed;
            opacity: 1;

            &,
            &:hover {
                background-color: lighten($secondary-color, 5);
                border-color: darken($secondary-color, 13);
                color: darken($secondary-color, 21);
            }
        }
    }

    &:hover,
    &:focus {
        background-color: #bebb36;
    }

    &.secondary {
        border-color: darken($secondary-color, 10%);
        text-decoration: none;
        color: lighten($secondary-color2, 20);

        &:hover {
            border-color: lighten($secondary-color2, 20);
            color: $secondary-color2;
        }
    }
}

.search-form-container {
    @include clearfix();
}

.validation-message {
    background-color: $error-color;
    color: $secondary-color;
    font-style: italic;
    padding: .5em;
    width: 100%;
    display: block;
    margin: 10px 0;
    font-weight: bold;
}

form {
    margin: 0;

    .actions.row {
        margin-top: 25px;
    }

    label {
        color: darken($secondary-color2, 20);
    }

    input,
    textarea,
    select,
    .k-autocomplete,
    .k-picker-wrap,
    .k-numeric-wrap,
    .k-dropdown-wrap {
        color: $secondary-color2 !important;

        &.outline-error {
            border-color: $error-color;
        }

        &.validation-element {
            border: 1px solid $error-color;
        }

        + .validation-message {
            margin-top: -10px;
            margin-bottom: 10px;
        }
    }

    .input-group {
        margin-bottom: 60px;

        &.small {
            margin-bottom: 40px;
        }

        h4 {
            border-bottom: 1px solid darken($secondary-color, 14);
            padding-bottom: .75em;
            color: darken($secondary-color2, 20);
        }
    }

    .row {
        label.inline,
        input,
        select,
        button,
        .button,
        .k-widget:not(.k-editor-widget) {
            margin-bottom: 10px;
        }

        label.inline {
            padding-bottom: 0;
        }
    }

    &.spacious {
        .row {
            label.inline,
            input,
            select,
            button,
            .button,
            .k-widget {
                margin-bottom: 14px;
            }
        }
    }

    div.languages {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
            margin-left: 10px;
            margin: 0 0 10px 10px !important;
        }
    }

    div.form-actions {
        text-align: right;
        padding-top: .75em;
        border-top: 1px solid darken($secondary-color, 14);
    }

    &.with-border {
        border-left: 1px solid darken($secondary-color, 14);
        padding-left: rem-calc(25);
        padding-top: rem-calc(25);
    }

    &.search-form {
        float: right;
        white-space: nowrap;

        input,
        button {
            display: inline-block;
            width: auto;
            margin: 0;
            height: auto;
            padding: .5rem;
            font-size: .9rem;
        }
    }
}

hr {
    border: 0;
    border-bottom: 1px solid darken($secondary-color, 14);
    margin: 1em 0;

    button + &,
    .button + & {
        margin-top: 0;
    }
}

div.picklist {
    margin-top: 10px;
    margin-bottom: 10px;

    span.cb {
        margin-right: 18px;
    }
    
    label {
        display: flex;
    }
}

.k-window-title {
    padding-left: .5em;
    padding-top: 1px;
}

.ui-widget {
    font-size: inherit !important;
    font-family: Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif !important;
}

.k-window-titlebar {
    background-color: #efefef;
}

.k-block > .k-header,
.k-window-titlebar {
    font-size: 1rem;
    font-weight: bold;
}

.k-window {
    //* {
    //    box-sizing: content-box;
    //}

    margin-top: 25px;

    .k-window-content *,
    textarea {
        //box-sizing: border-box;
    }

    .window-actions {
        text-align: right;
        margin: 1rem 0;

        &:first-of-type {
            border-bottom: 1px solid darken($secondary-color, 14);

            button,
            .button {
                margin: 0 0 1rem;
            }
        }

        &:last-of-type {
            border-top: 1px solid darken($secondary-color, 14);

            button,
            .button {
                margin: 1rem 0 0;
            }
        }
    }

    .window-content {
        max-height: 500px;
        overflow: auto;
    }
}

.k-dropdown {
    .k-select {
        border-left: 1px solid darken($secondary-color, 14);
    }
}

.k-upload {
    border-radius: 0;
    margin-bottom: 10px;

    .k-dropzone {
        border-radius: 0;
        @include clearfix();
        background: lighten($secondary-color, 5);
        padding: 0;
        position: relative;

        &::before {
            content: "- Selecteer -";
            margin-left: .6em;
            color: darken($secondary-color, 13);
            line-height: 2.5rem;
        }

        &.k-dropzone-active {
            &::before {
                display: none;
            }
        }

        .k-upload-status {
            line-height: 2.5rem;
            right: 40px;
            top: 0;
        }

        em {
            line-height: 2.5rem;
        }

        .k-upload-button {
            border-radius: 0;
            text-indent: -999px;
            overflow: hidden;
            background: lighten($secondary-color, 5) url("/images/icons/pencil.png") no-repeat center center;
            width: 2.5rem;
            min-width: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border: 0;
            border-left: 1px solid darken($secondary-color, 13);
            cursor: pointer;

            input::-webkit-file-upload-button {
                cursor: pointer;
            }
        }
    }
}

.k-window-content {
    .btn-group {
        text-align: right;
    }
}

.fi-info.help {
    transition: color 250ms ease-out;
    font-size: 120%;

    &:hover {
        color: $primary-color;
    }
}

.alert-box {
    &.floating {
        position: fixed;
        right: .5em;
        bottom: .5em;
        width: 250px;
        display: none;
    }
}

.input-clearer {
    position: relative;

    &::after {
        content: " ";
    }

    &::after,
    img.clear-icon {
        position: absolute;
        right: 11px;
        top: .22rem;
        width: 11px;
        height: 11px;
    }

    img.clear-icon {
        cursor: pointer;
    }

    &.search::after {
        background: url("/images/icons/magnifier.png") no-repeat center;
        background-size: cover;
    }

    &.not-empty {
        &::after {
            display: none;
        }
    }
}

.options-strip {
    @include clearfix();

    > .main {
        float: left;
    }

    > .secondary {
        float: right;
    }

    button,
    .button {
        margin: .5em 0;
    }
}

.tab-content {
    * {
        box-sizing: border-box !important;
    }

    .k-dropdown,
    .k-combobox {
        margin: 0 0 1em;
        width: 100% !important;

        .k-dropdown-wrap {
            padding: .05em;
            height: 2.5rem;
            background: lighten($secondary-color, 5) !important;

            input {
                box-shadow: inherit;
            }
        }
    }
}

table {
    width: 100%;

    &.collapsed {
        background-color: $table-even-row-bg;
    }

    tr th {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 2.5rem;
    }

    th {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
    }

    tbody {

        tr:nth-of-type(odd) {
            color: #663;
        }

        &,
        & a {
            color: $secondary-color2;
        }

        tr.active td {
            color: darken($secondary-color2, 20);
        }

        td,
        th {
            padding-left: 10px;
        }

        td.options {
            padding-left: 0;
            padding-right: 0;
            text-align: center;
            border-left: 1px solid #cbcbcb;
            white-space: nowrap;

            a {
                background-position: 0 0;

                &.action {
                    float: left;
                    margin: 0 0.5em;
                }
            }

            ul.button-group {
                display: inline-block;
            }

            &:not(.fi) {
                a:not(.fi) {
                    display: inline-block;
                    background: no-repeat center top;
                    text-indent: -9999px;
                    overflow: hidden;
                    width: 13px;
                    height: 13px;
                    vertical-align: inherit;

                    &:first-child {
                        //margin-left: 1em;
                    }

                    &:last-child {
                        //margin-right: 1em;
                    }

                    &.edit {
                        background-image: url("/images/icons/edit.png");
                    }

                    &.link {
                        background-image: url("/images/icons/link.png");

                        &.active {
                            background-position: bottom;
                        }
                    }

                    &.gear {
                        background-image: url("/images/icons/gear.png");
                    }

                    &.remove {
                        background-image: url("/images/icons/cross.png");
                    }

                    &:hover {
                        background-position: bottom;
                    }
                }
            }
        }
    }

    &.list {
        tr {
            border-top: 1px solid transparent;

            &.selected {
                background: $highlight-color-1 linear-gradient(to bottom, $highlight-color-1, $highlight-color-2);
                border: 1px solid darken($highlight-color-2, 5%);
            }
        }

        td.options {
            text-align: right;

            a {
                padding: 0 .5em;
            }
        }
    }

    tr .button {
        margin: 0 auto !important;
    }
}

.image-selector {
    border: 1px solid darken($secondary-color, 13);
    position: relative;
    background: lighten($secondary-color, 5);
    margin-bottom: 10px;

    img.preview {
        position: absolute;
        left: 0;
        height: 100%;
        border: 1px solid lighten($secondary-color, 5);
        max-width: 40px;
    }

    input {
        border: 0;
        margin: 0 !important;
        text-indent: 50px;
    }

    button {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        border: 0;
        border-left: 1px solid darken($secondary-color, 13);
        background-color: lighten($secondary-color, 5) !important;
        padding: 0;
        width: 2.5rem;
        margin: 0 !important;
    }
}

.sortable .image-selector img.preview {
    cursor: move;
}

.sortable-application {
    cursor: move;
}

h1,
h2,
h3 {
    font-family: "proxima_novasemibold", Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
    font-weight: normal !important; //important komt van coen
    text-transform: uppercase;
}

h2,
h3 {
    font-size: 153.846154%;
    color: #002453;

    &.content-heading {
        margin-bottom: 0;
        padding: .83em 0;
        border-bottom: 1px solid #ccc;
    }
}

td,
th {
    input {
        margin-bottom: 0 !important;
    }
}

a {
    color: darken($secondary-color2, 20);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

fieldset {
    position: relative;

    .alert-box {

        &.right {
            float: left;

            width: auto;
            margin: 0;
        }

        ul {
            margin: 0;
            padding-left: 25px;
        }
    }

    button.close {
        position: absolute;
        top: -1px;
        right: 4px;
    }

    &.secondary {
        background: #f5f5f5;

        legend {
            border: 1px solid darken($secondary-color, 7);
        }
    }

    &.language-specific {
        padding: 1.5em 1em 1em;
        position: relative;

        .language-selector {
            position: absolute;
            top: -.75em;
            right: 1em;
        }
    }
}

// Checkbox tree
ul.cb-tree {

    margin-top: 0;

    &,
    ul {
        list-style: none;
    }

    ul {
        padding-left: 2em;
    }

    li {
        padding: .25em 0 .25em .25em;
        color: $secondary-color2;

        &.with-subtree {
            &::before {
                content: " ";
                background: url("/images/icons/small_arrow.png") no-repeat;
                width: 4px;
                height: 8px;
                float: right;
                margin-top: 8px;
                transition: .25s transform;
                cursor: pointer;
            }

            &.expanded::before {
                transform: rotate(90deg);
            }

            &.with-checked {
                > span {
                    opacity: .7;
                }

                &.with-all-checked {
                    > span {
                        opacity: 1;
                        font-weight: bold;
                    }
                }
            }
        }

    }

    > li {
        border-bottom: 1px solid darken($secondary-color, 14);
        padding-right: 1em;
    }

    input[type=checkbox] {
        margin: 0 1em .5em 0;
    }
}

.options {
    margin-bottom: 40px;
}

.top-nav {
    margin-bottom: rem-calc(50);
    border-bottom: 1px solid darken($secondary-color, 14);

    ul {
        @include clearfix();
        list-style-type: none;
        margin: 0;
        margin-bottom: -1px;
        padding: 0;
    }

    li {
        float: left;
        padding: 0 rem-calc(25);
        margin: 0;
        border: 1px solid darken($secondary-color, 14);
        line-height: rem-calc(25);
        background: $secondary-color;

        + li {
            border-left: 0;
        }

        &.active {
            background: lighten($secondary-color, 5);
            border-bottom-color: lighten($secondary-color, 5);
            font-weight: bold;
        }

        a {
            color: lighten($secondary-color2, 20);
            display: block;
            text-decoration: none;
            padding: 2px 0;
        }

        a:hover,
        &.active a {
            color: $secondary-color2;
        }
    }
}

.overlay-container {
    position: relative;
    &.with-overlay::before {
        content: 'Uw gegevens worden verwerkt';
        display: block;
        position: fixed;
        max-width: 500px;
        width: 90%;
        padding: 30px 40px 50px 40px;
        color: #000;
        background-color: #fff;
        background-image: url(/images/icons/loading.gif);
        background-repeat: no-repeat;
        background-position: center 56px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 9999;
        text-align: center;
        box-shadow: 0 0 5px #ccc;
    }
    &.with-overlay::after {
        content: " ";
        display: block;
        background: lighten($secondary-color, 5);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; 
        opacity: .7;
        z-index: 999;
    }
}

.side-menu {

    h4 {
        margin: 2em 0 1em;

        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        padding: 0;
        list-style-type: none;
    }

    > ul {
        margin: 1em 0;
        border: 1px solid $table-border-color;

        li {

            &:nth-of-type(even) {
                background: $table-even-row-bg;
            }

            &:nth-of-type(odd) {
                background: $table-bg;
            }

            a {
                display: block;
                padding: .5em;

                &:hover {
                    text-decoration: none;
                    font-weight: bold;
                }
            }

            li {
                padding-left: 1em;
            }
        }

        > li > a:hover,
        & > li.selected > a,
        li:hover,
        li.selected {
            box-shadow: 0 0 2px rgba(0, 0, 0, .4);
            background: $highlight-color-1 linear-gradient(to bottom, $highlight-color-1, $highlight-color-2);
            position: relative;
        }
    }
}

ul.tabs {

    @include clearfix();

    margin: 0 0 rem-calc(20);
    padding: 0;
    border-left: 1px solid #c8c9cb;

    li {
        float: left;
        list-style: none;
        border: 1px solid #c8c9cb;
        border-left: 0;

        a {
            background: linear-gradient(to bottom, lighten($secondary-color, 5), darken($secondary-color, 2));
            padding: rem-calc(10);
            display: block;
            color: $secondary-color2;
            text-decoration: none;
        }

        &.active a,
        a:hover {
            background: linear-gradient(to bottom, lighten($secondary-color, 4), lighten($secondary-color, 5));
            box-shadow: inset 0 0 rem-calc(5) rgba(0, 0, 0, .1);
            color: darken($secondary-color2, 20);
        }
    }
}

.articles {
    article {
        border-radius: rem-calc(6);
        transition: background-color .2s ease-in, color .2s ease-in;
        background: #f4f5e5 no-repeat rem-calc(15) center;
        padding: rem-calc(10) rem-calc(20);
        display: block;
        line-height: 32px;
        font-weight: bold;

        div.title {
            font-weight: bold;
            font-size: 110%;
            color: #82814b;
            border-bottom: 1px solid #82814b;
            margin-bottom: rem-calc(20);

            span {
                margin-left: rem-calc(15);

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;
        }

        &:active,
        &:hover {
            background-color: #e3e8ee;
            text-decoration: none;
            color: darken($secondary-color2, 20);

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: #183a5f;
            }
        }
    }
}

.application header {

    .top-bar h1 {
        text-indent: $title-location-x;
    }
}

header {

    .top-bar {
        background: $secondary-color;
        border-bottom: 1px solid #c8c9cb;
        //@include clearfix();
        margin-bottom: rem-calc(60);

        .row {
            max-width: $row-width;
        }

        h1,
        li {
            color: $secondary-color2;
            font-family: "proxima_novasemibold", Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
            font-weight: normal;
        }

        h1 {
            font-size: 92.3076923%;
            font-weight: normal;
            text-transform: uppercase;
            float: left;
            margin: 0;
            line-height: $topbar-height;

            a {
                color: $secondary-color2;
                margin-left: -22px;
                background: url("/images/icons/home.png") no-repeat left 45%;
                padding-left: 22px;
                display: inline-block;
                text-decoration: none;
            }
        }

        a {
            text-decoration: none;
        }

        nav {
            float: right;

            ul {
                list-style-type: none;
            }

            > ul {
                margin: 0;
                padding: 0;
                border-left: 1px solid #c9cacc;
                height: $topbar-height;

                > li {
                    border-right: 1px solid #c9cacc;
                    border-left: 1px solid lighten($secondary-color, 5);
                    position: relative;
                    float: left;
                    text-transform: uppercase;
                    font-size: 92.3076923%;
                    line-height: $topbar-height;
                    padding: 0 rem-calc(18);
                    color: $secondary-color2;

                    > a {
                        background: no-repeat 0 center;
                        display: inline-block;
                        color: $secondary-color2;
                        text-decoration: none;
                    }

                    ul {
                        box-shadow: 2px 2px 2px rgba(0, 0, 0, .3);
                        border-left: 1px solid rgba(0, 0, 0, .3);
                        display: none;
                        position: absolute;
                        background: #fdfdfd;
                        z-index: 5;
                        left: 0;
                        top: $topbar-height;
                        padding: 0;
                        line-height: $topbar-height / 2;
                        border-top: 1px solid #c9cacc;
                        text-align: left;

                        > li > a {
                            display: block;
                            padding: 0 rem-calc(15);

                            &.current {
                                cursor: default;
                                text-decoration: none;
                                color: darken($secondary-color, 14);
                            }
                        }
                    }

                    &:hover ul {
                        display: block;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .content-header {

        h2 {
            margin: 0;
            margin-left: -3px;
            font-size: rem-calc(20);
            font-weight: normal;
            text-transform: uppercase;
            line-height: .75em !important;

            img {
                margin-right: rem-calc(20);
                vertical-align: bottom;
            }

            a {
                text-decoration: none;
            }
        }

        .login.panel {
            float: right;
            margin-bottom: 0;
        }
    }
}

#intranet-applications {
    width: 65%;
    float: left;

    .app-name {
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        display: block;
        font: 123.076923% "proxima_novabold", Helvetica, Arial, sans-serif;
        font-weight: normal !important;
        text-transform: uppercase;
        color: lighten($secondary-color, 5);
        padding: 12px;
        width: 100%;
    }

    ul {

        @include block-grid($per-row: 3, $spacing: 20px);

        li a {
            position: relative;
        }
    }

    + .news {
        width: 30%;
        float: right;

        h2 {
            border-bottom: 1px solid darken($secondary-color, 14);
            padding-bottom: 10px;
            margin-top: 0;
            margin-bottom: 15px;
        }

        article {
            border-bottom: 1px solid darken($secondary-color, 14);
            padding-bottom: 15px;
            margin-bottom: 15px;

            a.link {
                text-decoration: underline;
                font-weight: bold;
            }

            .row.collapse {
                border-bottom: 1px solid darken($secondary-color, 14);
                padding-bottom: 0;
                margin-bottom: 15px;
            }

            p {
                margin: 8px 0 !important;
            }
        }

        .title .date,
        .title .author {
            font-style: italic;
        }

        .title .title {
            font-weight: bold;
            display: inline-block;
            width: 100%;
            margin: 0 0 8px;
            color: #747474;
        }

        .view-all {
            float: right;
            text-decoration: underline;
            font-weight: bold;
        }

        .teaser {
            margin-top: .7em;
            margin-bottom: .8em;
        }
    }
}

.application.active,
.news.active {
    background: $secondary-color !important;
}

#intranet-login,
#intranet-password-reset,
#intranet-new-password {
    width: 50%;
    margin-left: $title-location-x;

    .action-buttons {
        float: right;
        width: auto;

        .button {
            float: left;

            &.secondary {
                float: right;
                margin-left: 8px;
            }
        }
    }
}

.language-selector {
    width: 100%;
    display: inline-block;
    margin: 4px 0;

    .languages {
        float: right;
    }
}

.languages .button {
    text-transform: uppercase;
    font-size: .9rem;
}

.loading .hide-on-loading {

    * {
        display: none;
    }

    &::after {
        display: block !important;
        content: " ";
        background: url("/images/icons/loading.gif") 50% center no-repeat;
        width: 100%;
        height: 32px;
    }
}

.busy {
    position: relative;
}

.load-box {
    width: 100%;
    background: url("/images/icons/loading.gif") 50% center no-repeat;
    min-height: 3em;
    &.secondary {
        background-color: lighten($secondary-color, 5);
    }
}

.large-12 {
    #intranet-manage-applications,
    #intranet-manage-users,
    #intranet-manage-news {
        //  table tbody tr.application:nth-child(2n),
        //  table tbody tr.user:nth-child(2n),
        //  table tbody tr.news:nth-child(2n){
        //    background:$secondary-color;
        //  }

        .button-group {
            button:hover,
            button:focus,
            .button:hover,
            .button:focus {
                box-shadow: none;
                background-color: transparent;
            }
        }

        table tbody tr .button-group li:nth-child(1n) {
            margin-right: 4px;
        }
    }
}

#intranet-manage-applications {
    td.image {
        span {
            background-color: $primary-color;
        }
    }

    .collapsed {
        ul {
            display: inline-block;
            vertical-align: top;
            border: 1px solid darken($secondary-color, 13);
        }
    }

    .large-4 {
        table tbody tr.application:nth-child(2n),
        table tbody tr.user:nth-child(2n) {
            background: none;
        }
    }
}

.file-list {

    margin: rem-calc(10) 0;

    li {
        position: relative;

        a.delete {
            position: absolute;
            right: 4px;
            top: -4px;
        }
    }
}

.flowed {
    display: flex;
    align-items: center;
    padding-bottom: rem-calc(10);

    > .flowed {
        padding-bottom: 0;
    }

    > *:not(:first-child) {
        margin-left: .5em;
    }

    .flowed {
        margin-left: 10em;
        flex-grow: 1;

        label {
            margin-right: 1em;
        }
    }

    @at-root form .row & {
        input,
        select,
        button,
        .button,
        .k-widget {
            margin-bottom: 0;
        }
    }
}

.button-group {
    padding: 0;

    li {
        margin-left: rem-calc(10);

        &:first-child {
            margin-left: 0;
        }
    }

    &.collapsed li {
        margin-left: 0;
    }
}

section {
    .content-wrap {
        padding: 0;
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(20);

        h3 {
            color: #002453;
            border-bottom: 1px solid darken($secondary-color, 14);
            padding-bottom: 10px;
            margin-bottom: 15px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.content-header {
    margin: 0 0 rem-calc(60);
}

// scss-lint:disable SelectorFormat
.full_content {
    .content-wrap {
        padding: 0;
    }
}

// scss-lint:enable SelectorFormat

.wide {
    .row,
    .top-bar .row {
        max-width: rem-calc(1600);
    }
}

.row.collapse {
    .columns + .columns {
        button,
        .button {
            margin-left: -1px;
        }
    }

    > .large-2.columns > button {
        width: 100%;
        padding: 0;
        text-align: center;
    }
}

footer {
    .footer-wrap {
        padding: rem-calc(15) 0;
        font-size: .9rem;
    }
}

.panel.login {
    padding-right: 0;
    padding-top: 0;
    border: 0;
    background: none;

    a {
        float: right;
        text-decoration: underline;
    }

    strong {
        color: #586027;
    }
}

//.alert-box.success {
//    background: none;
//    color: #009900;
//    border: none;
//    padding: 0;
//}
//
//.alert-box.alert {
//    background: none;
//    color: #ff0000;
//    border: none;
//    padding: 0;
//}

.explorertool {
    .explorertool-filelist {
        table tr td {
            padding-left: 10px !important;
        }

        .explorertool-views {
            padding-left: 10px;
        }

        .active * {
            font-style: italic !important;
        }
    }

    .explorertool-filelist-list-thumbs ul li input[type=checkbox] {
        margin: 0 auto;
        float: none;
        display: block;

    }

    .explorertool-filelist-list-list span {
        font-size: .9rem;
        display: block;
        padding-left: 0 !important;
        white-space: normal;
    }

    .explorertool-filetree {
        div.item span.text {
            font-size: 14px !important;
            width: 100% !important;
        }

        ul {
            padding-right: 1.25em;
        }
    }
}

#intranet-news article {
    border-bottom: 1px solid darken($secondary-color, 14);
    padding-bottom: 10px;
    margin-bottom: 15px;

    p:last-child {
        margin: 8px 0 0;
    }

    span.title {
        font-weight: bold;
        display: inline-block;
        width: 100%;
        color: $secondary-color2;

        a {
            color: $secondary-color2 !important;
        }
    }

    .teaser {
        margin-bottom: 1em;
    }

    .title .date,
    .title .author {
        font-style: italic;
    }

    .article-image {
        margin-bottom: 1em;
    }
}

//button.disabled, button[disabled], .button.disabled, .button[disabled] {
//    background: #BEBB36 !important;
//    opacity: 1;
//}

.large-4.picklist table tr:nth-child(2n) {
    background: none !important;
}

.large-4.picklist table tr.active {
    background: $secondary-color !important;
}

.large-8 .row .button.expand {
    width: auto;
    padding: 0 20px;
}

.explorertool-filelist-list-thumbs .cb {
    width: 100%;
    display: block;

    &.checked::after {
        content: url("/images/icons/checkbox_checked.png");
        position: absolute;
        left: 50%;
        margin-left: -5.5px;
        top: 1px;
    }
}

.explorertool-filelist-list-thumbs .cb::before {
    left: 50%;
    margin-left: -5.5px;
    top: 4px;
}

@media only screen and (max-width: 835px) {
    header h2 a {
        display: block;
        margin-bottom: 2.5rem;
    }
}

// Custom checkbox

.cb {
    position: relative;

    input[type=checkbox] {
        position: relative !important;
        opacity: 0;
        cursor: pointer;
        width: 11px;
        height: 18px;
        z-index: 2;
    }

    &::before {
        content: "";
        position: absolute;
        width: 11px;
        height: 11px;
        border: 1px solid darken($secondary-color, 14);
        background: lighten($secondary-color, 5);
        top: 2px;
        left: 0;
        cursor: pointer;
    }

    &.checked {
        &::before {
            border: 1px solid lighten($secondary-color2, 20);
            background: $secondary-color;
            color: darken($secondary-color2, 20);
        }

        &::after {
            content: url("/images/icons/checkbox_checked.png");
            position: absolute;
            left: 0;
            top: -1px;
            cursor: pointer;
        }
    }

    &.disabled {
        &::before {
            border: 1px solid #afafaf;
        }

        &::after {
            content: url("/images/icons/checkbox_checked_disabled.png");
        }
    }
}

form#databank {
    margin-top: 65px;
    margin-left: 150px;

    input[type=text],
    select,
    textarea,
    .field-wrap,
    .k-widget {
        width: 300px;

        &.large {
            width: 100%;
        }
    }

    .field-wrap {
        display: flex;

        input[type=text],
        select,
        textarea,
        .k-widget {
            width: auto;
            flex: 1;

            &.small {
                flex: .5;
            }
        }
    }
}

#databank-detail {
    border-top: 1px solid darken($secondary-color, 14);
    padding-top: 1em;

    .search-form-container {
        margin-bottom: 1em;
        padding-bottom: 2px;

        p {
            margin: 0;
        }
    }
    //scss-lint:disable SelectorFormat
    input.validationElement,
    .validationElement > .k-dropdown,
    .validationElement > .k-datepicker {
        border: 1px solid $error-color !important;
    }
    //scss-lint:enable SelectorFormat
}

// STYLING VAN COEN, MOET NOG UIT WORDEN GEZOCHT EN SAMEN WORDEN GEVOEGD

#current-news,
#news-overview,
#intranet-manage-news,
#intranet-manage-users,
#intranet-manage-applications {
    max-width: 762px;
}

#intranet-login label,
#intranet-password-reset label,
#intranet-new-password label {
    color: $secondary-color2;
    line-height: 2.5rem;
}

#intranet-login .row:nth-of-type(1) .small-9,
#intranet-login .row:nth-of-type(2) .small-9,
#intranet-password-reset .row:nth-of-type(1) .small-9,
#intranet-new-password .row:nth-of-type(1) .small-9 {
    width: 75%;
}

#intranet-login .small-3,
#intranet-password-reset .small-3 {
    width: 25%;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

#intranet-password-reset p {
    display: none;
    // Moet nog uit de code worden verwijderd
}

#intranet-password-reset label,
#intranet-new-password label {
    line-height: 2.5rem;
}

#intranet-password-reset .button,
#intranet-new-password .button {
    float: right;
}

#intranet-applications + .news .title .title a,
#intranet-applications + .news .title .title {
    color: $secondary-color2 !important;
    margin: 0 !important;
}

.news .large-block-grid-3.file-list > li {
    padding-left: 0 !important;
    padding-bottom: 0 !important;
}

.news .file-list {
    margin: 10px 0 5px !important;
}

.news .large-block-grid-3.file-list > li:nth-child(4) {
    display: none;
}

.news article .title:hover,
#news-overview .title:hover {
    cursor: pointer;
}

.news article:hover .title .title,
#news-overview article:hover .title .title {
    text-decoration: underline;
}

#current-news .images {
    margin-top: 2.5rem;
}

#current-news .large-block-grid-3 > li {
    width: 50% !important;
    padding: 0 .625em 2em !important;
}

#current-news article h2 {
    font: bold 20px Arial, "Bitstream Vera Sans", Helvetica, Verdana, sans-serif !important;
    color: $secondary-color2 !important;
    text-transform: none !important;
    margin-top: 3px;
}

#current-news article a.main-link {
    margin: 2.5rem 0 0;
}

.large-block-grid-3 > li:nth-of-type(3n+1) {
    clear: none !important;
}

#current-news .author {
    font-style: italic !important;
    font-size: 12px !important;
    margin: 0;
}

#intranet-news #current-news article {
    font-size: 14px !important;
}

#news-overview article img {
    width: 110px;
    height: auto;
    display: block;
    max-width: 110px;
}

#news-overview .large-1 {
    width: 110px;
}

#news-overview .large-11 {
    width: 745px;
}

#news-overview article span.title {
    margin: 0 0 6px;
}

//.button.secondary{
//    margin-right:4px !important;
//}
//
//.button.secondary:last-child{
//    margin-right:0 !important;
//}
//
//button.tiny, .button.tiny{
//    margin-right:4px;
//}
//
//button.tiny, .button.tiny:last-child{
//    margin:0;
//}

//scss-lint:disable SelectorFormat
#Intranet_Controller_Manage-applications tr td:nth-of-type(2) a,
#intranet-manage-users tr td:nth-of-type(1) a {
    display: block;
}

//scss-lint:enable SelectorFormat

#intranet-manage-users tr th:nth-of-type(2) {
    width: 300px;
}

#intranet-manage-users tr th:nth-of-type(3) {
    width: 135px;
}

#intranet-manage-users tr td:nth-of-type(1) {
    width: 375px;
}

//scss-lint:disable SelectorFormat
#Intranet_Controller_Manage-news table th:nth-of-type(1) {
    width: 470px;
}

#Intranet_Controller_Manage-news table th:nth-of-type(2) {
    width: 140px;
}

//scss-lint:enable SelectorFormat

.columns .picklist .applications .large-4 table tr.application td {
    color: darken($secondary-color2, 20) !important;
}

.columns .picklist .applications .large-4 table tbody tr.application td a {
    color: lighten($secondary-color2, 20) !important;
}

.large-4.picklist table tr.active span,
.large-4.picklist table tr.active td {
    font-weight: bold !important;
    color: darken($secondary-color2, 20) !important;
}

.languages .button,
.languages .button:hover,
.languages button:hover {
    text-transform: uppercase !important;
}

//scss-lint:disable SelectorFormat
#Intranet_Controller_User-login label.inline,
#Intranet_Controller_User-password-reset label.inline,
#Intranet_Controller_User-new-password label.inline {
    padding-top: 0;
    padding-bottom: 0;
}

//scss-lint:enable SelectorFormat

.explorertool-filetree-item {
    margin: 0 0 4px !important;
}

.explorertool-file-preview-tools .button.tiny {
    margin: 0 0 0 -1px !important;
    float: left !important;
    width: 26px;
    height: 26px;
    padding: 0 !important;
}

.ui-widget-content {
    border-top: 0 !important;

    .ui-icon-arrowthick-2-n-s {
        background: url("/images/icons/table_arrow.png") 0 center no-repeat !important;
    }
}

ul.sortable {
    border: none !important;
    margin: 40px 0;

    li {
        border-bottom: 1px solid darken($secondary-color, 14);
        padding-bottom: 8px;
        padding-left: 4px;
        padding-right: 4px;

        &.ui-sortable-helper {
            background: #eee !important;
        }

        button {
            &.delete {
                height: 11px;
                background-position: 0 0 !important;
                width: 12px;
                padding: 0 !important;
            }

            &.delete:hover {
                background-position: 0 -12px !important;
            }
        }
    }
}

//scss-lint:disable SelectorFormat
#explorertool_file_merge h3 {
    display: none;
}

#explorertool_file_merge .button.tiny {
    margin-bottom: 0 !important;
    margin-top: 6px;
    outline: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

//scss-lint:enable SelectorFormat

.explorertool-filelist button {
    margin-left: 10px !important;
}

.ui-sortable-helper {
    background: $secondary-color;
    border-top: 1px solid darken($secondary-color, 14);
}

.resourcespace {
    height: 100%;
    display: flex;
    //border-top: 1px solid #ccc;

    ul {
        padding: .25rem 1rem .25rem .25rem;
    }

    &-themetree {
        width: 15%;
        border-right: 1px solid #ccc;
    }

    &-collectiontree {
        width: 85%;
    }
}

// Begin BEM

.action-input {
    display: flex;

    &__button {
        margin-left: -1px;
    }
}

.validation-wrapper {
    &--error {
        > input,
        > select,
        > .k-widget {
            outline: 1px solid $error-color;
        }
    }
}

.sortable-list {
    @at-root ul#{&} {
        list-style: none;
        margin: 0;
    }

    &__collapser {
        width: 1rem;
        display: inline-block;
        cursor: pointer;
    }

    &__sortable-item {

        @at-root li#{&} {
            padding-left: 1rem;
        }

        border: 1px solid transparent;
        padding: .25em .5em;
        font-size: 14px;
        margin-bottom: .3em;

        &.ui-sortable-helper {
            border-top: 1px solid #cecece;
        }

        &--active,
        &:hover {
            cursor: pointer;
            background-color: $secondary-color;
            color: darken($secondary-color2, 20);
        }

    }
}

img.checked {
    border: 4px solid #122454;
}

.warning {
    color: $error-color;
}

table.table-white {
    background: #fff;
}

.k-window-titlebar.k-header {
    margin-top: -2em !important;
}