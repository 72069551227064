@import "../settings";

@mixin item-hover {
    background-color: #f2f2f2;
    border: none;
    color: #333;
    margin: 0;
}

@mixin item-selected {
    background-color: #f2f2f2;
    border: none;
    margin: 0;
}

@mixin item-active {
    background-color: #f2f2f2;
    border: none;
    margin: 0;
}

ul.sortable {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ddd;

    li {
        @include clearfix();
        cursor: move;
        padding: emCalc(5px) 0;

        .ui-icon {
            float: left;
            margin-top: 4px;
        }

        button.delete {
            float: right;
            margin: 0;
            margin-right: emCalc(5px);
        }

        .ui-sortable-placeholder {
            height: 32px;
        }
    }
}

.loading #explorertool_wrap {
    display: none;
}

img.file-preview {
    background-color: #fff;

}

img.file-preview.active {
    box-shadow: 0 0 5px #999500;
}

.explorertool {

    border-top: 1px solid #ccc;
    height: 100%;
    min-width: 960px;
    display: flex;
    position: relative;

    .loadoverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, .75) url(../images/icons/loading.gif) center no-repeat;
        z-index: 1000;
    }

    .explorertool-filetree {
        user-select: none;
        flex: 2;
        width: 15%;
        padding: emCalc(20px) 0;
        padding-bottom: 0;
        border-right: 1px solid rgba(0, 0, 0, .25);
    }
    .explorertool-filelist {
        user-select: none;
        width: 25%;
        flex: 3;
        padding: emCalc(20px);
        padding-bottom: 0;
        li.selected {
            background: none !important;
            box-shadow: none;

            img {
                box-shadow: 0 0 8px #999500;
            }
        }

        .explorertool-filelist-list {
            tr {
                label {
                    white-space: nowrap;
                }
                &.selected {
                    label {
                        font-weight: bold;
                    }
                }
            }
        }

    }

    .explorertool-filedetails-top {
        position: absolute;
        top: 12px;
        right: 0;
    }

    .explorertool-filedetails {
        width: 60%;
        flex: 10.5;
        border-left: 1px solid rgba(0, 0, 0, .25);
        padding: emCalc(20px);
        padding-bottom: 0;
        padding-right: 0;

        h2 {
            margin: 0 0 21px 0;
        }
    }

    table {

        input {
            margin: 0;
        }

        th {
            text-align: left;
        }
    }

    .explorertool-filetree {
        overflow: hidden;

       span.toggle {
           display: inline-block;
           vertical-align: top;
           margin-top: 3px;
           width:10px;
           transition: transform 0.25s;
       }

        div.item:hover {
            cursor: pointer;
        }

        div.item {
            display: block;
            white-space: nowrap;

            span.toggle {
                display: inline-block;
                vertical-align: top;
                margin-top: 3px;
                transition: transform 0.25s;
            }

            span.text {
                display: inline-block;
                width: 85%;
                white-space: normal;
                padding: emCalc(4px) emCalc(8px);
                &:hover {
                    @include item-hover();
                    border-radius: 2px;
                }
            }
        }
        .explorertool-filetree-item {

            transition: opacity 0.15s;

            &.selected {
                & > div.item span.text {
                    @include item-selected();
                    color: #333;
                }
            }
            &.active {
                & > div.item span.text {
                    @include item-active();
                    color: #333;
                }
            }
            &.leaf {
                & > div.item span.toggle {
                    display: none;
                }
            }
            &.loading {
                opacity: 0.25;
            }
            &.expanded {
                & > div.item span.toggle {
                    transform: rotate(90deg);
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }
        li {
            padding: 0;
            margin: 0;

            li {
                padding-left: emCalc(15px);
            }
        }
    }

    .explorertool-filelist {

        &__item {
            display: flex;

            &__checkbox {
                top: emCalc(1px);
                margin-right: emCalc(10px);
            }
        }

        table {
            width: 100%;
        }

        table tr td {
            padding: 4px;
            font-size: emCalc(13px);
        }

        .explorertool-views {
            display: inline-block;
            width: 100%;
            height: 39px;
            label {
                display: inline-block;
                margin-right: 10px;
            }
        }
        .active {
            * {
                font-weight: bold;
            }
        }
        li {
            padding-top: .5em;
            &.selected {
                @extend img.file-preview.active;
                background: #F0F7FF;
                position: relative;
            }
        }
        .explorertool-filelist-list-list {
            .selected td {
                @include item-active();
            }
        }
        .explorertool-filelist-list-thumbs {
            li {
                label {
                    white-space: nowrap;
                    overflow: hidden;
                }
                img {
                    display: block;
                }
            }
        }
    }

    .explorertool-filedetails {
        table {
            margin: emCalc(10px) auto;

            button {
                margin: 0;
            }

            tfoot {
                td {
                    border-top: 1px solid #ddd;
                    text-align: right;
                }
            }
        }

        .explorertool-file-details {
            @include clearfix();
            border: 1px solid #ddd;
            border-bottom: 0;
            padding: emCalc(5px);

            span {
                margin-left: emCalc(2px);
                margin-right: emCalc(4px);
            }

            button {
                float: right;
                margin: 0;
            }
        }

        .explorertool-file-preview {
            position: relative;
            padding: 0;
            margin: 0 0 0 emCalc(-20px);
            border: none;

            & > a {
                display: block;
                text-align: center;
                margin: emCalc(10px);
                padding: emCalc(30px);
                margin-bottom: 0;
            }

            .explorertool-file-preview-tools {
                transition: width 0.5s, opacity 0.5s;
                position: absolute;
                left: emCalc(5px);
                top: emCalc(20px);
                height: 100%;
                padding: emCalc(10px);

                .button {
                    display: block;
                    margin: -1px 0 0;
                }
            }

            button.secondary, .button.secondary {
                border-color: #999;
                line-height: 22px;
            }

            .explorertool-file-preview-pages {
                transition: height 0.5s, opacity 0.5s;
                box-shadow: 0 0 6px rgba(0, 0, 0, .15);
                background: #FCFCFC;
                white-space: nowrap;
                overflow-y: hidden;
                overflow-x: auto;
                position: relative;

                div {
                    display: inline-block;
                    padding: 0 emCalc(5px);

                    span {
                        font-weight: bold;
                        white-space: nowrap;
                    }

                    img {
                        margin: emCalc(10px) emCalc(5px);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.lt-ie10 {
    .explorertool {
        @include clearfix();
        height: 650px;

        .explorertool-filetree {
            float: left;
            width: 15%;
            height: 100%;
        }
        .explorertool-filelist {
            float: left;
            width: 25%;
            height: 100%;
        }
        .explorertool-filedetails {
            float: left;
            width: 60%;
            height: 100%;
        }
    }
}

.alert-box {
    width: 100%;
    display: inline-block;
}

.ui-dialog {
    font: 12px Arial, Helvetica, sans-serif;
}

.ui-dialog-title {
    font: 12px Arial, Helvetica, sans-serif;
}

ul.sortable li {
    font-size: 11px;
}

ul.sortable li span {
    margin-top: 5px;
    display: block;
}

#explorertool_file_merge button.delete {
    background: url(../images/icons/cross.png) center no-repeat;
    border: none;
    text-indent: -999px;
}

.ui-dialog .ui-dialog-buttonpane .ui-button-text {
    background: #999900;
    color: #fff;
    font: 12px Arial, Helvetica, Verdana, sans-serif;
}

.ui-dialog.ui-dialog{
    padding:0;
}

.ui-corner-all.ui-corner-all{
    border-radius: 0 !important;
}

.ui-widget-header.ui-widget-header{
    border:none !important;
}

.explorertool-file-preview-pages > div > span{
    display: none;
}

.rapportage-full {
    .alert-box.alert {
        background-color: transparent;
        border: none;
        color: #f04124;
        padding: 0;
        margin-bottom: 0;
    }
    table, th, tr, td {
        border: none !important;
    }
    tr {
        border-top: 1px solid #ccc !important;
    }
    thead > tr {
        border-top: none !important;
    }
    .alert-box.turbulent {
        color: $green !important;
    }
    .alert-box.laminair {
        color: $red !important;
    }
    .alert-box.overgangsgebied {
        color: $orange !important;
    }

}