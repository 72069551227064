@charset "UTF-8";
@import "settings";

i {
    &.medium {
        font-size: 120%;
    }

    &.large {
        font-size: 160%;
    }

    &.x-large {
        font-size: 200%;
    }

    &.active {
        text-shadow: 0 0 2px rgba(#fff, .7);
        color: $primary-color;
    }
}

table {

    color: #808080;

    &.bordered {
        th,
        td {
            border: 1px solid darken($secondary-color, 7);
        }
    }


    &.semi-bordered {
        tbody {
            th,
            td {
                border-left: 1px solid darken($secondary-color, 7);
                border-right: 1px solid darken($secondary-color, 7);

                &:first-child {
                    border-left: 0;
                }

                &:last-child {
                    border-right: 0;
                }
            }
        }
    }

    [align="center"] {
        text-align: center;
    }

    tr.sortable {

        td,
        th {
            span {
                position: relative;
                padding-right: 1.25em;
                cursor: pointer;

                &::before,
                &::after {
                    position: absolute;
                    right: 0;
                    display: inline-block;
                    line-height: .65rem;
                    font-size: .8rem;
                    color: #949494;
                }

                &::before {
                    content: "▴";
                    top: 0;
                }

                &::after {
                    content: "▾";
                    bottom: 0;
                }
            }

            &.sort {
                &.asc span::before {
                    color: #000;
                }

                &.desc span::after {
                    color: #000;
                }
            }
        }
    }

    thead {
        tr.filter-head,
        tr.filter {
            background: #efefef;

            td,
            th {
                padding-left: 10px !important;
            }
        }

        tr.filter-head {
            td,
            th {
                padding-bottom: .25em;
                text-transform: uppercase;
                font-weight: bold;
                font-size: em-calc(12px);
                color: #808080;
            }
        }

        tr.filter {
            td,
            th {
                padding-top: 0;

                .filter-input {
                    display: flex;
                }

                .k-widget {
                    width: 100%;

                    &.k-autocomplete,
                    &.k-datepicker {
                        input {
                            height: 2rem;
                            line-height: 2rem;
                            min-width: 2rem;
                        }
                    }

                    &.k-dropdown {
                        margin-left: -1px;
                        width: 2em;

                        .k-dropdown-wrap {
                            height: 2rem;
                            line-height: 2rem;
                        }

                        .k-select {
                            border-left: 0;
                        }

                        .k-icon.k-i-arrow-s {
                            background-position: -32px -80px;
                        }
                    }
                }
            }

            + tr {
                td,
                th {
                    padding-top: 2em !important;
                    padding-bottom: .5em !important;
                }
            }
        }
    }

    &.with-filter {
        td,
        th {
            padding-left: 10px;
        }
    }

    td,
    th {
        &.options {
            padding: 0 !important;
        }
    }
}

.list-loader {
    text-align: center;
}
